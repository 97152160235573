/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
//@ts-ignore
import nftVideo from "../assets/nft-video.mp4";

interface UnStakeInterface {
  image: any;
  name: string;
  index: number;
  selected: boolean;
  nft: any;
  handleOrderCollect: (e: boolean, i: number) => void;
}

const UnStake = (props: UnStakeInterface) => {
  return (
    <div className="flex-basis">
      <label
        htmlFor={"unstaking" + props.index}
        className="cursor-pointer block relative cardTop p-3 rounded-borderRadiusCard mb-mbcard bg-gradient-to-r from-[#B1952740] to-[#4A7ACD40]"
      >
        <div className="rounded-borderRadiusCard relative mt-mtcard">
          <video
            autoPlay={true}
            playsInline={true}
            loop={true}
            controls={false}
            muted={true}
            className="w-full relative imgTop"
          >
            <source src={nftVideo} />
          </video>
        </div>
        <div className="pt-ptcard px-2 flex items-center justify-between staking">
          <h4>{props.name}</h4>
          <div className="flex items-center">
            <input
              id={"unstaking" + props.index}
              type="checkbox"
              name={"unstaking" + props.index}
              checked={props.selected}
              className="hidden"
              onChange={(e) => {
                props.handleOrderCollect(e.target.checked, props.index);
              }}
            />
            <label
              htmlFor={"unstaking" + props.index}
              className="flex items-center cursor-pointer"
            >
              <span className="w-6 h-6 inline-block mr-1 rounded-full border border-white opacity-50"></span>
            </label>
          </div>
        </div>
      </label>
    </div>
  );
};

export default UnStake;
